export default {
    self: {
        url: 'http://localhost:3000'
    },
    // apidev: {
    //     url: 'https://api.fairwear.test',
    //     memberHub: 'https://fwf--develop.sandbox.my.site.com/public/services/apexrest',
    // },

    api: {
        url: 'https://wp.fairwear.org',
        memberHub: '/public/services/apexrest'
    },
    resources: {
        url: 'https://fwf--develop.sandbox.my.site.com/resources/'
    },
    showTransparencyPolicy: true,
    algolia: {
        applicationId: '3GSLNUTHJ9',
        apiKey: '51550019a3204e4b4c7c05cb39be841a'
    }
};
