export default {
    self: {
        url: 'https://www.fairwear.org'
    },
    api: {
        url: 'https://api.fairwear.org',
        memberHub: 'https://fwf.my.site.com/public/services/apexrest'
    },
    resources: {
        url: 'https://fwf.my.site.com/resources/'
    },
    bugsnagId: 'b1a833550155b79a15951b3079c5a1dc',
    algolia: {
        applicationId: '3PXMYQTSS9',
        apiKey: 'f1d0f90654489832abb3091ea753aa79'
    },
    showTransparencyPolicy: true,
    googleTagManagerId: 'GTM-MCXXCFZ'
};
