<template>
    <div
        class="navigation-dropdown"
        :class="{ 'navigation-dropdown--open': open }"
        @mouseenter="open = true"
        @mouseleave="open = false"
        @focus="open = true"
        @blur="open = false"
    >
        <nuxt-link
            v-if="mainLink"
            :to="mainLink.to"
        >
            {{ mainLink.title }}
        </nuxt-link>

        <menu
            v-if="links.length > 0"
            class="navigation-dropdown__body"
        >
            <li v-for="(link, index) in links" :key="index">
                <nuxt-link :to="link.to">
                    {{ link.title }}
                </nuxt-link>
            </li>
        </menu>

        <div class="navigation-dropdown__arrow" v-if="links.length > 0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.47402 7.85269C4.84043 7.48628 5.43449 7.48628 5.80089 7.85268L11.1027 13.1544C11.4932 13.545 12.1263 13.545 12.5169 13.1544L17.8186 7.85269C18.185 7.48628 18.7791 7.48628 19.1455 7.85269C19.5119 8.21909 19.5119 8.81315 19.1455 9.17956L12.5169 15.8082C12.1263 16.1987 11.4932 16.1987 11.1027 15.8082L4.47402 9.17956C4.10762 8.81315 4.10762 8.21909 4.47402 7.85269Z" fill="black" fill-opacity="0.88" />
            </svg>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NavigationDropdown',
    props: {
        mainLink: {
            type: Object,
            default: () => {}
        },
        links: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            open: false
        };
    },
    watch: {
        $route() {
            if (document) {
                document.activeElement.blur();
            }

            this.open = false;
        }
    }
};
</script>

<style lang="less">
.navigation-dropdown {
    position: relative;
    padding-right: 2rem;
    height: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    white-space: nowrap;
    text-align: left;

    a {
        text-decoration: none;
        color: unset;

        &:hover {
            color: @color-primary;
        }
    }

    &:focus-within,
    &.navigation-dropdown--open {
        > a {
            color: @color-primary;
        }

        .navigation-dropdown__body {
            display: block;
        }

        .navigation-dropdown__arrow {
            color: @color-primary;
            transform: rotate(180deg);
        }
    }

    .navigation-dropdown__arrow {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1.5rem;
        height: 1.5rem;

        transform-origin: 50% 25%;

        path {
            fill: currentColor;
        }
    }
}

.navigation-dropdown__body {
    position: absolute;
    top: 100%;
    left: 0;
    background: white;
    border: 1px solid rgba(0, 0, 0, .13);
    box-shadow: 0 0 5rem 0 rgba(0, 0, 0, .125);
    list-style: none;
    margin: 0;
    padding: 0;
    display: none;

    li {
        margin: 1.5rem;
    }
}
</style>
