import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _46898c99 = () => interopDefault(import('../pages/404.vue' /* webpackChunkName: "pages/404" */))
const _6e33b984 = () => interopDefault(import('../pages/brands/index.vue' /* webpackChunkName: "pages/brands/index" */))
const _e4e58b9c = () => interopDefault(import('../pages/join-the-movement/index.vue' /* webpackChunkName: "pages/join-the-movement/index" */))
const _a15d01ce = () => interopDefault(import('../pages/our-impact/index.vue' /* webpackChunkName: "pages/our-impact/index" */))
const _3cc79dee = () => interopDefault(import('../pages/programmes/index.vue' /* webpackChunkName: "pages/programmes/index" */))
const _f3714580 = () => interopDefault(import('../pages/resource/index.vue' /* webpackChunkName: "pages/resource/index" */))
const _4ed58249 = () => interopDefault(import('../pages/resources/index.vue' /* webpackChunkName: "pages/resources/index" */))
const _fe2820be = () => interopDefault(import('../pages/resources-and-tools/index.vue' /* webpackChunkName: "pages/resources-and-tools/index" */))
const _2cca0137 = () => interopDefault(import('../pages/stories/index.vue' /* webpackChunkName: "pages/stories/index" */))
const _536dcfcc = () => interopDefault(import('../pages/styleguide.vue' /* webpackChunkName: "pages/styleguide" */))
const _d9d15e1a = () => interopDefault(import('../pages/about-us/our-team/index.vue' /* webpackChunkName: "pages/about-us/our-team/index" */))
const _3e770178 = () => interopDefault(import('../pages/brands/former-members/index.vue' /* webpackChunkName: "pages/brands/former-members/index" */))
const _cb4376a4 = () => interopDefault(import('../pages/programmes/countries/index.vue' /* webpackChunkName: "pages/programmes/countries/index" */))
const _4c97f0ba = () => interopDefault(import('../pages/resources-and-tools/factories/index.vue' /* webpackChunkName: "pages/resources-and-tools/factories/index" */))
const _55299606 = () => interopDefault(import('../pages/resources-and-tools/resource-documents/index.vue' /* webpackChunkName: "pages/resources-and-tools/resource-documents/index" */))
const _0e6374b8 = () => interopDefault(import('../pages/resources-and-tools/factories/thank-you.vue' /* webpackChunkName: "pages/resources-and-tools/factories/thank-you" */))
const _23e2fe1a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _02acf52e = () => interopDefault(import('../pages/programmes/countries/_slug/index.vue' /* webpackChunkName: "pages/programmes/countries/_slug/index" */))
const _1fe8f0a7 = () => interopDefault(import('../pages/stories/category/_slug/index.vue' /* webpackChunkName: "pages/stories/category/_slug/index" */))
const _585ec224 = () => interopDefault(import('../pages/resources-and-tools/factories/_factory/contact.vue' /* webpackChunkName: "pages/resources-and-tools/factories/_factory/contact" */))
const _54d437f9 = () => interopDefault(import('../pages/brands/_slug/index.vue' /* webpackChunkName: "pages/brands/_slug/index" */))
const _309d8184 = () => interopDefault(import('../pages/programmes/_slug/index.vue' /* webpackChunkName: "pages/programmes/_slug/index" */))
const _58ae687b = () => interopDefault(import('../pages/resource/_slug/index.vue' /* webpackChunkName: "pages/resource/_slug/index" */))
const _2159ee1c = () => interopDefault(import('../pages/resources-and-tools/_slug/index.vue' /* webpackChunkName: "pages/resources-and-tools/_slug/index" */))
const _2164e2c4 = () => interopDefault(import('../pages/resources/_slug/index.vue' /* webpackChunkName: "pages/resources/_slug/index" */))
const _378df732 = () => interopDefault(import('../pages/stories/_slug/index.vue' /* webpackChunkName: "pages/stories/_slug/index" */))
const _593a5bc4 = () => interopDefault(import('../pages/wp-content/_.vue' /* webpackChunkName: "pages/wp-content/_" */))
const _4ad3de56 = () => interopDefault(import('../pages/ul/_.vue' /* webpackChunkName: "pages/ul/_" */))
const _362f64fb = () => interopDefault(import('../pages/_slug/_.vue' /* webpackChunkName: "pages/_slug/_" */))
const _2fdd1300 = () => interopDefault(import('../pages/_.vue' /* webpackChunkName: "pages/_" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _46898c99,
    name: "404"
  }, {
    path: "/brands",
    component: _6e33b984,
    name: "brands"
  }, {
    path: "/join-the-movement",
    component: _e4e58b9c,
    name: "join-the-movement"
  }, {
    path: "/our-impact",
    component: _a15d01ce,
    name: "our-impact"
  }, {
    path: "/programmes",
    component: _3cc79dee,
    name: "programmes"
  }, {
    path: "/resource",
    component: _f3714580,
    name: "resource"
  }, {
    path: "/resources",
    component: _4ed58249,
    name: "resources"
  }, {
    path: "/resources-and-tools",
    component: _fe2820be,
    name: "resources-and-tools"
  }, {
    path: "/stories",
    component: _2cca0137,
    name: "stories"
  }, {
    path: "/styleguide",
    component: _536dcfcc,
    name: "styleguide"
  }, {
    path: "/about-us/our-team",
    component: _d9d15e1a,
    name: "about-us-our-team"
  }, {
    path: "/brands/former-members",
    component: _3e770178,
    name: "brands-former-members"
  }, {
    path: "/programmes/countries",
    component: _cb4376a4,
    name: "programmes-countries"
  }, {
    path: "/resources-and-tools/factories",
    component: _4c97f0ba,
    name: "resources-and-tools-factories"
  }, {
    path: "/resources-and-tools/resource-documents",
    component: _55299606,
    name: "resources-and-tools-resource-documents"
  }, {
    path: "/resources-and-tools/factories/thank-you",
    component: _0e6374b8,
    name: "resources-and-tools-factories-thank-you"
  }, {
    path: "/",
    component: _23e2fe1a,
    name: "index"
  }, {
    path: "/programmes/countries/:slug",
    component: _02acf52e,
    name: "programmes-countries-slug"
  }, {
    path: "/stories/category/:slug",
    component: _1fe8f0a7,
    name: "stories-category-slug"
  }, {
    path: "/resources-and-tools/factories/:factory?/contact",
    component: _585ec224,
    name: "resources-and-tools-factories-factory-contact"
  }, {
    path: "/brands/:slug",
    component: _54d437f9,
    name: "brands-slug"
  }, {
    path: "/programmes/:slug",
    component: _309d8184,
    name: "programmes-slug"
  }, {
    path: "/resource/:slug",
    component: _58ae687b,
    name: "resource-slug"
  }, {
    path: "/resources-and-tools/:slug",
    component: _2159ee1c,
    name: "resources-and-tools-slug"
  }, {
    path: "/resources/:slug",
    component: _2164e2c4,
    name: "resources-slug"
  }, {
    path: "/stories/:slug",
    component: _378df732,
    name: "stories-slug"
  }, {
    path: "/wp-content/*",
    component: _593a5bc4,
    name: "wp-content-all"
  }, {
    path: "/ul/*",
    component: _4ad3de56,
    name: "ul-all"
  }, {
    path: "/:slug/*",
    component: _362f64fb,
    name: "slug-all"
  }, {
    path: "/*",
    component: _2fdd1300,
    name: "all"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
