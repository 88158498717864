<template>
    <div
        class="navigation"
        :class="{
            'navigation--collapsed': !showMenu,
            'navigation--expanded': showMenu,
            'search--collapsed': !showSearchInput,
            'search--expanded': showSearchInput
        }"
    >
        <client-only>
            <ais-instant-search
                :search-client="searchClient"
                index-name="wp_searchable_posts"
            >
                <!--<a id="skip-nav" class="screenreader-text" href="#main-content" tabindex="0">
                    Skip to Content
                </a>-->

                <div class="navigation__bar">
                    <!-- Logo -->
                    <nuxt-link name="Fair wear foundation" class="navigation__logo" to="/">
                        <span class="navigation__home sr-only">Home</span>
                    </nuxt-link>

                    <menu class="navigation__nav">
                        <li>
                            <navigation-dropdown
                                :main-link="{
                                    to: '/about-us',
                                    title: 'About'
                                }"
                                :links="[
                                    {
                                        to: '/about-us/get-to-know-fair-wear',
                                        title: 'Get to know Fair Wear'
                                    },
                                    {
                                        to: '/about-us/labour-standards',
                                        title: 'What we stand for'
                                    },
                                    {
                                        to: '/about-us/our-partners',
                                        title: 'Our collaborations'
                                    },
                                    {
                                        to: '/stories/category/news',
                                        title: 'Our latest stories'
                                    },
                                    {
                                        to: '/about-us/our-team',
                                        title: 'Our team'
                                    },
                                    {
                                        to: '/join-the-movement/vacancies',
                                        title: 'Vacancies'
                                    },
                                    {
                                        to: '/support/press',
                                        title: 'Press and media'
                                    },
                                    {
                                        to: '/support',
                                        title: 'Support'
                                    }
                                ]"
                            />
                        </li>
                        <li>
                            <navigation-dropdown
                                :main-link="{
                                    to: '/our-impact',
                                    title: 'Our impact'
                                }"
                                :links="[
                                    {
                                        to: '/our-approach-to-hrdd',
                                        title: 'Our approach to HRDD'
                                    },
                                    {
                                        to: '/programmes/complaints',
                                        title: 'Access to remedy'
                                    },
                                    {
                                        to: '/programmes/gender',
                                        title: 'Gender'
                                    },
                                    {
                                        to: '/meaningful-stakeholder-engagement',
                                        title: 'Meaningful stakeholder engagement'
                                    },
                                    {
                                        to: '/responsible-purchasing-practices',
                                        title: 'Responsible purchasing practices'
                                    },
                                    {
                                        to: '/programmes/living-wage',
                                        title: 'Living wages'
                                    },
                                    {
                                        to: '/programmes/dialogue',
                                        title: 'Social dialogue and freedom of association'
                                    },
                                    {
                                        to: '/other',
                                        title: 'Other'
                                    }
                                ]"
                            />
                        </li>
                        <li>
                            <navigation-dropdown
                                :main-link="{
                                    to: '/join-the-movement/',
                                    title: 'Join the movement'
                                }"
                                :links="[
                                    {
                                        to: '/programmes/brands',
                                        title: 'Working with brands'
                                    },
                                    {
                                        to: '/join-the-movement/become-a-member',
                                        title: 'Join Fair Wear as brand'
                                    },
                                    {
                                        to: '/join-the-movement/enabling-garment-and-footwear-brands-to-conduct-hrdd',
                                        title: 'HRDD Academy'
                                    },
                                    {
                                        to: '/brands',
                                        title: 'Meet our member brands'
                                    },
                                    {
                                        to: '/programmes/brand-performance-check',
                                        title: 'Brand performance checks'
                                    },
                                    {
                                        to: '/brands/former-members',
                                        title: 'Former member brands'
                                    }
                                ]"
                            />
                        </li>

                        <li>
                            <navigation-dropdown
                                :main-link="{
                                    to: '/where-we-work',
                                    title: 'Where we work'
                                }"
                                :links="[
                                    {
                                        to: '/programmes/countries',
                                        title: 'Our work in production countries'
                                    },
                                    {
                                        to: '/our-impact/lobby-advocacy',
                                        title: 'Engaging with policy'
                                    }
                                ]"
                            />
                        </li>
                        <li>
                            <navigation-dropdown
                                :main-link="{
                                    to: '/resource',
                                    title: 'Resources'
                                }"

                            />
                        </li>
                    </menu>

                    <!-- Search button -->
                    <button
                        name="Toggle search"
                        class="navigation__toggle navigation__toggle--search navigation__bar-item"
                        @click="toggleAndClearSearchInput"
                    >
                        <span class="navigation__alt sr-only">Toggle search</span>
                        <search-icon class="navigation__icon" aria-hidden="true"/>
                        <ais-clear-refinements aria-hidden="true">
                            <close class="navigation__icon" aria-hidden="true"/>
                        </ais-clear-refinements>
                    </button>

                    <!-- Menu button -->
                    <button
                        name="Toggle menu"
                        class="navigation__toggle navigation__toggle--menu navigation__bar-item"
                        @click="toggleMenu"
                    >
                        <span class="navigation__alt sr-only">Toggle menu</span>
                        <hamburger class="navigation__icon" aria-hidden="true"/>
                        <close class="navigation__icon" aria-hidden="true"/>
                    </button>

                    <!-- Memberhub button -->
                    <a
                        href="https://memberhub.fairwear.org/s/"
                        class="navigation__toggle navigation__toggle--memberhub navigation__bar-item"
                    >
                        <svg
                            class="navigation__icon"
                            xmlns="http://www.w3.org/2000/svg"
                            width="32"
                            height="32"
                            viewBox="0 0 32 32"
                            fill="none"
                        >
                            <path d="M4 26.6667C7.11439 23.3634 11.3427 21.3333 16 21.3333C20.6573 21.3333 24.8856 23.3634 28 26.6667M22 10C22 13.3137 19.3137 16 16 16C12.6863 16 10 13.3137 10 10C10 6.68629 12.6863 4 16 4C19.3137 4 22 6.68629 22 10Z"
                                  stroke="black"
                                  stroke-width="3"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"/>
                        </svg>
                    </a>

                    <!-- To top button -->
                    <button
                        v-show="scrolled"
                        name="To top"
                        class="navigation__to-top navigation__bar-item"
                        @click="toTop"
                    >
                        <span class="navigation__alt sr-only">To top</span>
                        <to-top class="navigation__icon" aria-hidden="true"/>
                    </button>

                    <!-- Search input -->
                    <ais-search-box
                        v-show="showSearchInput"
                        ref="searchBox"
                        class="navigation__search-input"
                    >
                        <form class="ais-SearchBox-form" novalidate action="" @submit.prevent="">
                            <debouncedSearchBox v-slot="{ query: value, refine }">
                                <input
                                    ref="searchInput"
                                    type="text"
                                    inputmode="text"
                                    autocorrect="off"
                                    autocapitalize="none"
                                    autocomplete="off"
                                    spellcheck="false"
                                    required="required"
                                    maxlength="512"
                                    aria-label="Search"
                                    placeholder="Search…"
                                    class="ais-SearchBox-input"
                                    :value="value"
                                    @input="onInput(refine, $event.target.value)"
                                >
                            </debouncedSearchBox>
                            <button tabindex="-1" type="submit" title="Search" class="ais-SearchBox-submit">
                                <span class="ais-SearchBox-submitIcon"/>
                            </button>
                        </form>
                    </ais-search-box>
                </div>

                <!-- Search results -->
                <search-results
                    v-show="showResults"
                    class="navigation__search-results"
                    :title="searchTitle"
                    :title-short="searchTitleShort"
                    :root-path="searchFilter"
                    :visible="showResults"
                    @closeResults="toggleSearchInput"
                />
            </ais-instant-search>
        </client-only>

        <main-menu class="navigation__menu" @closeMenu="toggleMenu"/>
    </div>
</template>

<script>

import algoliasearch from 'algoliasearch/lite';
import { AisClearRefinements, AisInstantSearch, AisSearchBox } from 'vue-instantsearch';
import { mapGetters, mapState } from 'vuex';
import config from '~/config/config';
import Close from '~/patterns/atoms/close/close';
import DebouncedSearchBox from '~/patterns/atoms/debounced-search-box/debounced-search-box';

import Hamburger from '~/patterns/atoms/hamburger/hamburger';
import SearchIcon from '~/patterns/atoms/search-icon/search-icon';
import ToTop from '~/patterns/atoms/to-top/to-top';
import MainMenu from '~/patterns/molecules/main-menu/main-menu';
import SearchResults from '~/patterns/molecules/search-results/search-results';

import NavigationDropdown from './navigation-dropdown.vue';

const algoliaClient = algoliasearch(
    config.algolia.applicationId,
    config.algolia.apiKey
);
const searchedIndexes = {};
const searchClient = {
    search(requests) {
        // debugger
        const indexName = requests[0].indexName;
        if (!requests[0].params?.facets?.length && requests.every(({ params }) => !params.query)) {
            // Just return empty results if no query is provided
            console.log('not searching...: ' + indexName)
            return Promise.resolve({
                results: [{
                    hits: [],
                    page: 0,
                    nbPages: 0,
                    nbHits: 0,
                    hitsPerPage: 0,
                    processingTimeMS: 0,
                    index: indexName
                }]
            });
        }
        console.log('Searching...: ' + indexName)

        searchedIndexes[indexName] = true;
        return algoliaClient.search(requests);
    },
    future: {
        preserveSharedStateOnUnmount: true,
    }
};
export default {

    components: {
        Hamburger,
        SearchIcon,
        Close,
        ToTop,
        MainMenu,
        AisInstantSearch,
        AisSearchBox,
        AisClearRefinements,
        SearchResults,
        DebouncedSearchBox,
        NavigationDropdown
    },

    data() {
        return {
            showMenu: false,
            scrolled: false,
            scrollPosition: 0,
            searchClient,
            showSearchInput: false,
            query: ''
        };
    },

    computed: {
        ...mapState(['search/filter']),
        ...mapGetters({
            currentPage: 'pages/currentPage',
            menu: 'menu/menuFlat',
            searchFilter: 'search/filter',
            searchTitle: 'search/title',
            searchTitleShort: 'search/titleShort'
        }),

        showResults() {
            return this.showSearchInput;// && (this.query !== '' || this.searchFilter !== '');
        },

        currentTitle() {
            let title = '';
            const path = this.$route.fullPath;

            // try to find title in main menu
            this.menu.forEach((main) => {
                if (main.url === path) {
                    title = main.title;
                } else if (main.items) {
                    main.items.forEach((item) => {
                        if (item.url === path) {
                            title = item.title;
                        }
                    });
                }
            });

            if (title === '' && this.currentPage && this.currentPage.titleForced) {
                title = this.currentPage.titleForced;
            }

            // try to find title in current page
            if (title === '' && this.currentPage && this.currentPage.title) {
                title = this.currentPage.title.rendered;
            }

            // return title
            return title;
        }
    },

    watch: {
        searchFilter(value) {
            if (value) {
                this.expandSearch();
                this.scrollPosition = window.scrollY;
                this.$nextTick(() => this.toTop(false));
            }
        }
    },

    mounted() {
        window.addEventListener('scroll', this.handleScroll);
    },

    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        onInput(refine, value) {
            this.query = value;
            refine(value);
        },
        /**
         * Toggle expanded
         */
        toggleMenu(value) {
            if (value === false) {
                this.showMenu = false;
                this.$emit('navigation-collapsed');

                return;
            }

            // already expanded? collapse menu
            if (this.showMenu) {
                // collapse
                this.showMenu = false;
                this.$emit('navigation-collapsed');

                // scroll back to remembered position
                this.$nextTick(() => window.scroll(0, this.scrollPosition));
                return;
            }

            // remember scroll position and scroll to top
            this.scrollPosition = window.scrollY;

            // expand
            this.$emit('navigation-expanded');
            this.$emit('search-collapsed');
            this.showMenu = true;
            this.showSearchInput = false;
        },

        /**
         * Toggle expanded
         */
        toggleSearchInput() {
            // already expanded? collapse menu
            if (this.showSearchInput) {
                this.collapseSearch();
                return;
            }

            // expand
            this.expandSearch();
        },

        toggleAndClearSearchInput() {
            // already expanded? collapse menu
            if (this.showSearchInput) {
                this.query = '';
            }

            this.toggleSearchInput();
        },

        expandSearch() {
            // remember scroll position and scroll to top
            this.scrollPosition = window.scrollY;

            this.$emit('search-expanded');
            this.$emit('navigation-collapsed');
            this.showSearchInput = true;
            this.showMenu = false;

            // focus on search field
            this.$nextTick(() => this.$refs?.searchInput?.focus());
            this.$nextTick(() => window.scroll(0, 0));
        },

        collapseSearch() {
            // collapse
            this.$store.commit('search/setFilter', '');
            this.$store.commit('search/setTitle', '');
            this.$store.commit('search/setTitleShort', '');
            this.showSearchInput = false;
            this.$emit('search-collapsed');

            // scroll back to remembered position
            this.$nextTick(() => window.scroll(0, this.scrollPosition));
        },

        /**
         * Scroll smoothly to top
         */
        toTop(animate) {
            if (animate && 'scrollBehavior' in document.documentElement.style) {
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                window.scrollTo(0, 0);
            }
        },

        /**
         * Window scrolled
         */
        handleScroll() {
            if (window.scrollY > 50) {
                this.scrolled = true;
                return;
            }

            this.scrolled = false;
        }
    }
};
</script>

<style src="./navigation.less" lang="less" scoped/>
